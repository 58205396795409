export const URL_INSTA_FLYNOW = "https://www.instagram.com/appflynow";
export const URL_FACEBOOK = "https://www.facebook.com/appflynow";
export const URL_WHATSAPP = "https://api.whatsapp.com/send?phone=5531999107753";
export const URL_EMAIL = "mailto:support@appflynow.com";
export const URL_YOUTUBE = "https://www.youtube.com/@appflynow?sub_confirmation=1";

export const URL_SITE_ROGERD = "https://rogerdribeiro.com/?ref=flynow";

export const API_URL = "https://api.appflynow.com";
export const URL_EXTENSION_CHROME = "https://chrome.google.com/webstore/detail/flynow-produtividade-h%C3%A1bi/ienkeacpabjckghdhdbogicopahccimc?hl=pt-BR";
export const URL_EXTENSION_FIREFOX = "https://addons.mozilla.org/en-US/firefox/addon/flynow-produtividade/";
export const URL_APP_WEB = "https://web.appflynow.com/entrar?utm_source=siteFlynow&utm_medium=home&utm_campaign=appweb";
export const URL_APP_FINANCES_WEB = "https://web-finances.appflynow.com";

export const URL_APP_ANDROID= "https://play.google.com/store/apps/details?id=com.flynow&referrer=utm_source%3DsiteFlynow%26utm_medium%3Dhome%26utm_term%3Ddownload";
export const URL_APP_IOS= "https://apps.apple.com/app/apple-store/id1536269005?pt=122223947&ct=siteFlynow&mt=8";

export const URL_APP_FRASES_ANDROID= "https://play.google.com/store/apps/details?id=com.flynow.frases&referrer=utm_source%3DsiteFlynow%26utm_medium%3Dhome%26utm_term%3Ddownload";
export const URL_APP_FRASES_IOS= "https://apps.apple.com/br/app/flynow-frases-de-motiva%C3%A7%C3%A3o/id1565042754";

export const URL_APP_ANDROID_LINKS= "https://play.google.com/store/apps/details?id=com.flynow&referrer=utm_source%3DinstagramFlynow%26utm_medium%3Dlinks%26utm_term%3Ddownload";
export const URL_APP_IOS_LINKS= "https://apps.apple.com/app/apple-store/id1536269005?pt=122223947&ct=instaLinks&mt=8";
export const URL_APP_FINANCES_ANDROID= "https://play.google.com/store/apps/details?id=com.flynow.finances&referrer=utm_source%3DsiteFlynow%26utm_medium%3Dhome%26utm_term%3Ddownload";
export const URL_APP_FINANCES_IOS= "https://apps.apple.com/br/app/id1611814185";

export const URL_HOTMART_COMBO_EBOOK = "https://pay.hotmart.com/L47211687W";
export const URL_HOTMART_EBOOK_METAS = "https://pay.hotmart.com/U52775454L";
export const URL_HOTMART_EBOOK_HABITOS = "https://pay.hotmart.com/E52775045D";
export const URL_HOTMART_EBOOK_PRODUTIVIDADE = "https://pay.hotmart.com/D61045485C";
export const URL_HOTMART_COMBO_3_EBOOKS = "https://pay.hotmart.com/Y64229600X";
export const URL_HOTMART_CURSO_HABITOS = "https://pay.hotmart.com/K85071358G";
export const URL_HOTMART_CURSO_PRODUTIVIDADE = "https://pay.hotmart.com/L94578684T";

export const EBOOKS = [
  {
    id: 'ebook_produtividade',
    url: '/guia-definitivo-para-a-produtividade',
    title: 'Guia definitivo para a produtividade',
    description:'23 técnicas (+6 BÔNUS) para ser mais produtivo e alcançar seus objetivos!',
    img: require('../assets/images/ebookProdutividade.png')
  },
  {
    id: 'ebook_metas',
    url: '/metas-e-planejamento',
    title: 'Metas e Planejamento',
    description:'6 passos essenciais para criar metas + 6 tipos de planejamentos + 3 bônus EXCLUSIVOS em PDF!',
    img: require('../assets/images/ebookMetas.png')
  },
  {
    id: 'ebook_habitos',
    url: '/guia-completo-habitos',
    title: 'Guia completo sobre hábitos',
    description:'10 táticas (+2 BÔNUS) incríveis para criar e manter hábitos no longo prazo!',
    img: require('../assets/images/ebookHabitos.png')
  },
  {
    id: 'ebook_combo',
    url: '/combo-ebooks-produtividade-habitos-metas',
    title: 'COMBO: Ebooks Produtividade + Hábitos + Metas e Planejamentos',
    description:'Tenha em mãos todas as ferramentas para alcançar sua melhor versão!',
    img: require('../assets/images/combo.png')
  }
];
export const APPS = [
  {
    id: 'app_prod',
    url: '/apps/productivity',
    title: 'Flynow - Produtividade, Hábitos e Metas',
    description: 'Tenha acesso aos melhores métodos de gestão de tarefas, hábitos e metas para atingir criar bons hábitos e atingir seus objetivos!',
    img: require('../assets/images/logo.png')
  },
  {
    id: 'app_finances',
    url: '/apps/finances',
    title: 'Flynow - Finanças Pessoais',
    description: 'Tenha controle sobre seus gastos, ganhos e orçamentos. Estabeleça metas financeiras e obtenha estatísticas e gráficos sobre suas finanças, tudo em um lugar só!',
    img: require('../assets/images/app/finances/logo.png')
  },
  {
    id: 'app_phrases',
    url: '/apps/phrases',
    title: 'Flynow - Frases de Motivação & Sabedoria',
    description: 'Tenha um empurrãozinho diário para começar o dia inspirado com frases sobre motivação, sabedoria, reflexão e muito mais!',
    img: require('../assets/images/app/frases/logo.png')
  },
];
export const COURSES = [
  {
    id: 'course_habitos',
    url: '/curso-domine-seus-habitos',
    title: 'Domine seus hábitos: A chave para uma vida extraordinária',
    description:`Desbloqueie seu verdadeiro potencial e construa uma vida extraordinária baseada em hábitos poderosos! ✨`,
    img: require('../assets/images/cursoHabitos.png')
  },
  {
    id: 'course_produtividade',
    url: '/curso-domine-a-arte-da-produtividade',
    title: 'Domine a arte da produtividade',
    description:'Descubra como ser mais produtivo e gerir melhor seu tempo para alcançar os seus objetivos - sem lista interminável de tarefas e com muito mais tempo livre para fazer o que quiser. 🚀',
    img: require('../assets/images/cursoProdutividade.png')
  },
];

export const IMAGES_APPS = {
  main: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fapps_main.png?alt=media&token=f3871c8b-715d-4d11-b322-6f9c53b72c20',
}
export const IMAGES_APP_PRODUCTIVITY = {
  main: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_main.png?alt=media&token=d3db9bb8-a9b7-48cf-a156-c574c77d3db1',
  gamification: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_gamification.png?alt=media&token=021fb694-affa-4f46-9895-847587bb73d9',
  goals: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_goals.png?alt=media&token=7677a376-f0f4-433c-aed2-d84339e56834',
  habits: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_habits.png?alt=media&token=1fae0624-64f8-4a47-80de-eb5882bc96a3',
  tasks: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_task.png?alt=media&token=a72a3a40-b7b3-42fb-b6f4-b0a523bfa1fb',
  modeview: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_mode_view.png?alt=media&token=a2b1a552-6a79-4154-b434-fd80829d989d',
  statistics: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_statistics.png?alt=media&token=f327d102-f845-4058-92f6-239a87e78440',
  tools: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_tools.png?alt=media&token=b8525d91-7b4f-4175-90e4-be2f73cd44a4',
  web: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_web.png?alt=media&token=c437b88c-17b1-415f-b60f-5154d536ace2',
  reports: 'https://firebasestorage.googleapis.com/v0/b/flynow-24551.appspot.com/o/images%2Fpage-app%2Fapp_productivity_reports.png?alt=media&token=4636a0f7-49c1-46ed-9052-df897fc27f45'
}
export const IMAGES_APP_FINANCES = {
  main: 'https://firebasestorage.googleapis.com/v0/b/finances-415dc.appspot.com/o/screenshots%2Fmain.png?alt=media&token=72e64bbe-c683-4c7e-baa4-8b46c78df2a5',
  home: 'https://firebasestorage.googleapis.com/v0/b/finances-415dc.appspot.com/o/screenshots%2Fhome.png?alt=media&token=72e64bbe-c683-4c7e-baa4-8b46c78df2a5',
  transactions: 'https://firebasestorage.googleapis.com/v0/b/finances-415dc.appspot.com/o/screenshots%2Ftransactions.png?alt=media&token=72e64bbe-c683-4c7e-baa4-8b46c78df2a5',
  statistics: 'https://firebasestorage.googleapis.com/v0/b/finances-415dc.appspot.com/o/screenshots%2Fstatistics.png?alt=media&token=72e64bbe-c683-4c7e-baa4-8b46c78df2a5',
  goals: 'https://firebasestorage.googleapis.com/v0/b/finances-415dc.appspot.com/o/screenshots%2Fgoals.png?alt=media&token=72e64bbe-c683-4c7e-baa4-8b46c78df2a5',
  categories: 'https://firebasestorage.googleapis.com/v0/b/finances-415dc.appspot.com/o/screenshots%2Fcategories.png?alt=media&token=72e64bbe-c683-4c7e-baa4-8b46c78df2a5',
  web: 'https://firebasestorage.googleapis.com/v0/b/finances-415dc.appspot.com/o/screenshots%2Fweb.png?alt=media&token=72e64bbe-c683-4c7e-baa4-8b46c78df2a5',
  budgets: 'https://firebasestorage.googleapis.com/v0/b/finances-415dc.appspot.com/o/screenshots%2Fbudgets.png?alt=media&token=72e64bbe-c683-4c7e-baa4-8b46c78df2a5',
}
